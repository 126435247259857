import React from 'react'
import { Link } from 'gatsby'
import { Email, Facebook, Instagram, Location, Phone } from './icons'
import { useSiteSettings } from '../hooks/useSiteSettings'

const Footer = () => {
  const { phoneNumbers, address, email, facebookPage, instagramPage } = useSiteSettings()

  return (
    <footer>
      <div className="container flex-footer" id="contacts">
        <Link to="/">
          <div className="logo box">
            <span className="logo-text logo-text__top">книжкова</span>
            <span className="logo-text logo-text__bottom">крамниця</span>
          </div>
        </Link>
        <div className="box">
          <Location color="#f69b43" size="30" />
          <div>
            <span className="box__text">{address}</span>
          </div>
        </div>
        <div className="box">
          <Phone color="#f69b43" size="34" />
          <div>
            {phoneNumbers.map(phone => <a href={`tel:${phone}`} className="box__text" key={phone.replace(/\D/g,'')}>{phone}</a>)}
          </div>
        </div>
        <div className="box">
          <Email color="#f69b43" size="30" />
          <div>
            <a href={`mailto:${email}`} className="box__text">{email}</a>
          </div>
        </div>
        <div className="media-box box">
          <a className="media-link" href={facebookPage} target="_blank" rel="noopener noreferrer">
            <Facebook color="#f69b43" size="30" />
          </a>
          <a className="media-link" href={instagramPage} target="_blank" rel="noopener noreferrer">
            <Instagram color="#f69b43" size="30" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer