import { useStaticQuery, graphql } from 'gatsby'

export const useSiteSettings = () => {
  const { file } = useStaticQuery(
    graphql`
      query SiteSettings {
        file(name: {eq: "site"}) {
          childConfigYaml {
            phoneNumbers
            address
            email
            facebookPage
            instagramPage
          }
        }
      }
    `
  )
  return file.childConfigYaml
}