import React from 'react'
import { Link } from 'gatsby'
import { ShopingCart } from './icons'
import Counter from './Counter'

const Header = () => {
  return (
    <header className="header">
      <div className="header-bottom">
        <div className="container">
          <Link to="/">
            <div className="logo">
              <span className="logo__text logo__top">книжкова</span>
              <span className="logo__text logo__bottom">крамниця</span>
            </div>
          </Link>
          <div className="header-toolbar">
            <Link className="shoping-cart__link" to="/cart">
              <div className="shoping-cart">
                <div className="shoping-cart__img">
                  <ShopingCart color="#f69b43" size="30" />
                </div>
                <div className="shoping-cart__counter">
                  <Counter />
                </div>
              </div>
            </Link>
            <div className="hamburger-box">
              <div className="menu">
                <input className="menu__btn" type="checkbox" id="menu__btn" />
                <label className="menu__icon" htmlFor="menu__btn">
                  <span className="menu__navicon"></span>
                </label>
                <ul className="hamburger-list">
                  <li className="hamburger-list__item">
                    <Link className="hamburger-list__link" to="/bookstore">
                      книгарня
                    </Link>
                  </li>
                  <li className="hamburger-list__item">
                    <Link to="#" className="hamburger-list__link" onClick={(e) => {
                      e.preventDefault()
                      document.getElementById("contacts").scrollIntoView()
                    }}>
                      контакти
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header