import React from 'react'
import { useSelector } from 'react-redux'

const Counter = () => {
  const count = useSelector((state) => {
    let itemsCount = 0;
    state.cart.forEach((item) => itemsCount += item.count)
    return itemsCount;
  })
  return <p className="book-counter">{(count > 0) ? count : ''}</p>
}

export default Counter