import * as React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const NotFoundPage = () => (
  <div className="not-found-box">
      <Header />
      <div className="not-found-msg">Вибачте, але такої сторінки не існує!</div>
      <Footer />
  </div>
)

export default NotFoundPage