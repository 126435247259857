import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Phone = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M14,2.5H10A4.507,4.507,0,0,0,5.5,7V17A4.507,4.507,0,0,0,10,21.5h4A4.507,4.507,0,0,0,18.5,17V7A4.507,4.507,0,0,0,14,2.5ZM12.5,19h-1a1,1,0,0,1,0-2h1a1,1,0,0,1,0,2Z" />
    </svg>
  );
});

Phone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Phone.displayName = 'Phone';

export default Phone;
